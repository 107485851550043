export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは8月より%d点上がりました。季節の変わり目も良い生活習慣ができていますね。<br>\n10月のテーマは、<b>❝感染症に備える！打ち勝つ！❞​</b> です。これからの季節は、低温度低湿度になりやすく、様々な感染症が流行しやすいです。バランスの良い食事と睡眠を十分にとり、疲労を溜めないようにしましょう。さらに、適度な運動で代謝を良くして、感染症に負けない免疫力をつけましょう。\n\n",
      "down": "生活習慣スコアは8月より%d点下がりました。身体の声に耳を傾けて、労わってあげましょう。<br>\n10月のテーマは、<b>❝感染症に備える！打ち勝つ！❞​</b> です。これからの季節は、低温度低湿度になりやすく、様々な感染症が流行しやすいです。バランスの良い食事と睡眠を十分にとり、疲労を溜めないようにしましょう。さらに、適度な運動で代謝を良くして、感染症に負けない免疫力をつけましょう。\n\n",
      "equal100": "生活習慣スコアは8月に引き続き%満点%です。素晴らしい！このまま継続していきましょう。<br>\n10月のテーマは、<b>❝感染症に備える！打ち勝つ！❞​</b> です。これからの季節は、低温度低湿度になりやすく、様々な感染症が流行しやすいです。バランスの良い食事と睡眠を十分にとり、疲労を溜めないようにしましょう。さらに、適度な運動で代謝を良くして、感染症に負けない免疫力をつけましょう。\n\n",
      "equal": "生活習慣スコアは8月と同じ点数です。<br>\n10月のテーマは、<b>❝感染症に備える！打ち勝つ！❞​</b> です。これからの季節は、低温度低湿度になりやすく、様々な感染症が流行しやすいです。バランスの良い食事と睡眠を十分にとり、疲労を溜めないようにしましょう。さらに、適度な運動で代謝を良くして、感染症に負けない免疫力をつけましょう。\n\n"
    },
    "steps": {
      "up": "歩数スコアは8月より%d点上がりました。<br>\n歩く機会が増えていますね。10月24日から<b>Walk個人チャレンジ</b>が始まります。この調子で、「歩くこと」を習慣化させましょう。\n\n",
      "down": "歩数スコアは8月より%d点下がりました<br>\n10月24日から<b>Walk個人チャレンジ</b>が始まります。豪華賞品GETのチャンスも！ぜひ、アプリよりエントリーしてください。\n\n",
      "equal": "歩数スコアは8月と同じ点数です。<br>\n10月24日から<b>Walk個人チャレンジ</b>が始まります。豪華賞品GETのチャンスも！ぜひ、アプリよりエントリーしてください。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは8月より%d点上がりました。<br>\n運動すると、血液やリンパの流れが良くなり、酸素や栄養が身体のすみずみまで行き届きます。全身の細胞が活発になり、免疫力アップに！\n\n",
      "down": "運動のスコアは8月より%d点下がりました。<br>\n運動すると、血液やリンパの流れが良くなり、酸素や栄養が身体のすみずみまで行き届きます。全身の細胞が活発になり、免疫力アップに！\n\n",
      "equal": "運動のスコアは8月と同じ点数です。<br>\n運動すると、血液やリンパの流れが良くなり、酸素や栄養が身体のすみずみまで行き届きます。全身の細胞が活発になり、免疫力アップに！\n\n"
    },
    "meal": {
      "up": "食事のスコアは8月より%d点上がりました。<br>\n秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理もオススメ。\n\n",
      "down": "食事のスコアは8月より%d点下がりました。<br>\n秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理もオススメ。\n\n",
      "equal": "食事のスコアは8月と同じ点数です。<br>\n秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理もオススメ。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは8月より%d点上がりました。<br>\n自分のペースでゆっくりと楽しみながらお酒を飲みましょう。他人と話したり、食べながら飲むのも良いですね♪\n\n",
      "down": "飲酒のスコアは8月より%d点下がりました。<br>\n糖質オフ・糖質ゼロビールを目にしたことはありますか？<a href=\"https://go.andwell.jp/library/b645fe09-2129-11ee-8f80-0ab1e6a5e015\">こちらのコラム</a>で、あなたに合った糖質オフビールの選び方をご紹介！\n\n",
      "equal": "飲酒のスコアは8月と同じ点数です。<br>\n糖質オフ・糖質ゼロビールを目にしたことはありますか？<a href=\"https://go.andwell.jp/library/b645fe09-2129-11ee-8f80-0ab1e6a5e015\">こちらのコラム</a>で、あなたに合った糖質オフビールの選び方をご紹介！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは8月より%d点上がりました。<br>\n免疫力は、寝ている時に<b>維持・強化</b>され、疲労回復したり、風邪などの病気の予防に役立つと言われています。睡眠を十分にとり、免疫力を高めていきましょう。\n\n",
      "down": "睡眠のスコアは8月より%d点下がりました。<br>\n免疫力は、寝ている時に<b>維持・強化</b>され、疲労回復したり、風邪などの病気の予防に役立つと言われています。睡眠を十分にとり、免疫力を高めていきましょう。\n\n",
      "equal": "睡眠のスコアは8月と同じ点数です。<br>\n免疫力は、寝ている時に<b>維持・強化</b>され、疲労回復したり、風邪などの病気の予防に役立つと言われています。睡眠を十分にとり、免疫力を高めていきましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは8月より%d点上がりました。<br>\n過度なストレスは、免疫力低下に。身体をほぐすことで、ストレス軽減につながります。<a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">こちらのコラム</a>にある30秒ストレッチをお試しください。\n\n",
      "down": "ストレスのスコアは8月より%d点下がりました。<br>\n過度なストレスは、免疫力低下に。身体をほぐすことで、ストレス軽減につながります。<a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">こちらのコラム</a>にある30秒ストレッチをお試しください。\n\n",
      "equal": "ストレスのスコアは8月と同じ点数です。<br>\n過度なストレスは、免疫力低下に。身体をほぐすことで、ストレス軽減につながります。<a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">こちらのコラム</a>にある30秒ストレッチをお試しください。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle Score has gone up by %d points since August. Great job keeping up good lifestyle habits during the changing seasons.<br>The theme for October is <b>\"Fight against infectious disease! \"</b>. It's getting easier and easier to get sick due to lowering temperatures and humidity. Eat a well-balanced diet and get plenty of sleep to avoid fatigue. Be sure to also build up your immunity by improving your metabolism with moderate exercise.",
      "down": "Your lifestyle score has dropped by %d points since August. Pay attention to your body and keep working hard.<br>The theme for October is <b>\"Fight against infectious disease! \"</b>. It's getting easier and easier to get sick due to lowering temperatures and humidity. Eat a well-balanced diet and get plenty of sleep to avoid fatigue. Be sure to also build up your immunity by improving your metabolism with moderate exercise.",
      "equal100": "Your Lifestyle Score is perfect, just as it was in August. Excellent! Keep up the great work!<br>The theme for October is <b>\"Fight against infectious disease! \"</b>. It's getting easier and easier to get sick due to lowering temperatures and humidity. Eat a well-balanced diet and get plenty of sleep to avoid fatigue. Be sure to also build up your immunity by improving your metabolism with moderate exercise.",
      "equal": "Your Lifestyle Score is the same as it was in August.<br>The theme for October is <b>\"Fight against infectious disease! \"</b>. It's getting easier and easier to get sick due to lowering temperatures and humidity. Eat a well-balanced diet and get plenty of sleep to avoid fatigue. Be sure to also build up your immunity by improving your metabolism with moderate exercise."
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since August.<br>\nThe opportunities to walk are increasing, and the <b>Individual Walk Challenge</b> begins on October 24. Keep up the good work and turn walking into a habit.\n\n",
      "down": "Your steps score has gone down %d pts since August. <br>\nThe <b>Individual Walk Challenge</b> will start on October 24th. There will be a chance to win wonderful prizes! Please enter through the app.\n\n",
      "equal": "Your steps score is the same as August. <br>\nThe <b>Individual Walk Challenge</b> will start on October 24th. There will be a chance to win wonderful prizes! Please enter through the app.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since August.<br>\nExercise improves blood and lymph flow, allowing oxygen and nutrients to reach all parts of the body. Cells throughout the body become more active, and the immune system is strengthened!\n\n",
      "down": "Your exercise score has gone down %d pts since August.<br>\nExercise improves blood and lymph flow, allowing oxygen and nutrients to reach all parts of the body. Cells throughout the body become more active, and the immune system is strengthened!\n\n",
      "equal": "Your exercise score is the same as August.<br>\nExercise improves blood and lymph flow, allowing oxygen and nutrients to reach all parts of the body. Cells throughout the body become more active, and the immune system is strengthened!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since August.<br>\nA sign that fall is here, mushrooms are rich in water-soluble nutrients. Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.\n\n",
      "down": "Your food score has gone down %d pts since August.<br>\nA sign that fall is here, mushrooms are rich in water-soluble nutrients. Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.\n\n",
      "equal": "Your food score is the same as August.<br>\nA sign that fall is here, mushrooms are rich in water-soluble nutrients. Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since August.<br>\nEnjoy drinks at your own pace. It's also good to chat with people and drink while eating.\n\n",
      "down": "Your alcohol score has gone down %d pts since August.<br>\nHave you ever seen sugar-free beer? Learn how to choose the right sugar-free beer for you in <a href=\"https://go.andwell.jp/library/b645fe09-2129-11ee-8f80-0ab1e6a5e015\">this column</a>!\n\n",
      "equal": "Your alcohol score is the same as August.<br>\nHave you ever seen sugar-free beer? Learn how to choose the right sugar-free beer for you in <a href=\"https://go.andwell.jp/library/b645fe09-2129-11ee-8f80-0ab1e6a5e015\">this column</a>!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since August.<br>\nThe immune system is <b>strengthened and maintained</b> while we sleep, which helps us recover from fatigue and prevent colds and other illnesses. Make sure to get enough sleep to strengthen your immune system.\n\n",
      "down": "Your sleep score has gone down %d pts since August.<br>\nThe immune system is <b>strengthened and maintained</b> while we sleep, which helps us recover from fatigue and prevent colds and other illnesses. Make sure to get enough sleep to strengthen your immune system.\n\n",
      "equal": "Your sleep score is the same as August.<br>\nThe immune system is <b>strengthened and maintained</b> while we sleep, which helps us recover from fatigue and prevent colds and other illnesses. Make sure to get enough sleep to strengthen your immune system.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since August.<br>\nExcessive stress can weaken the immune system. Taking some time to relax your body will help reduce stress. Try the 30-second stretches in <a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">this column</a>.\n\n",
      "down": "Your stress score has gone down %d pts since August.<br>\nExcessive stress can weaken the immune system. Taking some time to relax your body will help reduce stress. Try the 30-second stretches in <a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">this column</a>.\n\n",
      "equal": "Your stress score is the same as August.<br>\nExcessive stress can weaken the immune system. Taking some time to relax your body will help reduce stress. Try the 30-second stretches in <a href=\"https://go.andwell.jp/library/46d43e32-036c-11ee-a8af-06528b823331\">this column</a>.\n\n"
    }
  }
}
